<template>
  <div class="home">
    <v-row class="d-flex justify-center mt-2">
      <v-col cols="11" class="my-4">
        <strong class="py-3">Misión: </strong>
        <p class="text--primary body-1 text-justify">
          Somos un emprendimiento payanes con servicios físicos y digitales nacido en 
          2019 con el propósito de suplir una necesidad desde la logística para eventos en
          servicios sociales y recreacionales en el sur del país. Apoyado en plataformas 
          digitales y en la era de la informática, BINGOS POP-COL LITOGRAFIA empezó a escalar 
          y ampliar los servicios que inicialmente se ofertaron, siendo así como en este momento 
          podemos ofrecer un amplio portafolio en el sector de impresión digital y análoga que 
          complementan desde la publicidad y aseguran el éxito de un evento de bingo. 
          Por nuestra vasta experiencia estamos en la capacidad de hacer un acompañamiento 
          integral a sus eventos de bingo desde la logística, producción y estrategias para 
          los mismos revisando, ajustando y sugiriendo como abordar todos los procesos que 
          se tienen que tener en cuenta para la realización de un evento de gran magnitud si así 
          el cliente lo solicita.
        </p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center mt-2">
      <v-col cols="11" class="my-4">
        <strong class="py-3">Visión: </strong>
        <p class="text--primary body-1 text-justify">
          Crecer como empresa mejorando y ampliando todos los servicios ofertados. 
          Logrando este objetivo con socios y alianzas estratégicas que fortalezcan 
          las opciones que les ofrecemos a nuestros clientes tanto en lo físico como
          en el talento humano, a mediano plazo el objetivo es dar a conocer en el 
          resto del país un sistema que facilita la realización de los bingos ya sean 
          presenciales o virtuales y que a su ves evitan cualquier intento de 
          falsificar o adulterar las tablas de bingo.
        </p>
      </v-col>
    </v-row>

    <img src="@/assets/logo.jpg" class="img-logo mt-3" alt="">
  </div>
</template>

<style scoped>
  .img-logo {
    width: 24em;
    margin-left: 2em;
  }
</style>