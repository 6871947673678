

import { defineComponent, computed, ref } from 'vue';

//Components
import SideBar from './layouts/SideBar/SideBar.vue';
import AppBar from './layouts/AppBar/AppBar.vue';
import { useAuth } from './composables';

export default defineComponent({
  name: 'App',
  components: { SideBar, AppBar },
  setup() {

    const { logout, autologin, getUser } = useAuth();

    const nameApp = "BINGOS POP-COL LITOGRAFIA";
    const nitApp = "1061734032-9";
    const whatsAppContact = "3245101905-3215234185"

    const initFunction = async () => {
      await autologin();
    }

    initFunction();

    const loggedIn = computed( () => getUser.value );
    const isAdmin = computed( () => getUser.value && getUser.value.role == 2 );
    const isNormal = computed( () => getUser.value && getUser.value.role == 1 );
    const isEmployee = computed( () => getUser.value && getUser.value.role == 3 );

    const logoutSession = () => {
      logout();
    }
    
    return {
      drawer: ref(true),
      logoutSession,
      nameApp,
      nitApp,
      whatsAppContact,
      loggedIn,
      isAdmin,
      isNormal,
      isEmployee,
      getUser,
    }
  }
});

