

import { defineComponent, toRef } from "vue";


	export default defineComponent({
		name: 'SidebarVue',
		props: {
			drawer: {
				type: Boolean,
				required: true,
				default: true,
			},
			loggedIn: {
				type: Object,
				required: true,
				default: null
			},
			isAdmin: {
				type: Boolean,
				required: true,
				default: false,
			},
			isNormal: {
				type: Boolean,
				required: true,
				default: false,
			},
			isEmployee: {
				type: Boolean,
				required: true,
				default: false,
			},
			getUser: {
				type: Object,
				required: true,
				default: {},
			}
		},
		setup(props) {
			const drawer = toRef(props, 'drawer');

			return {
				drawer: drawer,
			}
		}
	});

