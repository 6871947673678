import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-172c1b5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_AppBar = _resolveComponent("AppBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_SideBar, {
        drawer: _ctx.drawer,
        loggedIn: _ctx.loggedIn,
        isAdmin: _ctx.isAdmin,
        isNormal: _ctx.isNormal,
        getUser: _ctx.getUser,
        isEmployee: _ctx.isEmployee
      }, null, 8, ["drawer", "loggedIn", "isAdmin", "isNormal", "getUser", "isEmployee"]),
      _createVNode(_component_AppBar, {
        nameApp: _ctx.nameApp,
        onToggleDrawer: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer = !_ctx.drawer)),
        logoutSession: _ctx.logoutSession,
        loggedIn: _ctx.loggedIn,
        isAdmin: _ctx.isAdmin,
        isNormal: _ctx.isNormal
      }, null, 8, ["nameApp", "logoutSession", "loggedIn", "isAdmin", "isNormal"]),
      _createVNode(_component_v_main, {
        app: "",
        class: "body"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { fluid: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_footer, {
        app: "",
        color: "grey-darken-3",
        dark: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("strong", _hoisted_1, [
            _createElementVNode("span", null, " © " + _toDisplayString(_ctx.nameApp), 1),
            _createElementVNode("span", null, " Nit: " + _toDisplayString(_ctx.nitApp), 1),
            _createElementVNode("span", null, " Contacto: " + _toDisplayString(_ctx.whatsAppContact), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}