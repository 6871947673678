
import { defineComponent, toRef } from "vue";
 
	export default defineComponent({
		name: 'AppbarVue',
		props: {
			nameApp: {
				type: String,
				required: true,
			},
			logoutSession: {
				type: Function,
				required: true
			},
			loggedIn: {
				type: Object,
				required: true,
				default: null
			}
		},
		
		setup(props, { emit }) {
			const nameApp = toRef(props, "nameApp");

		 	const toggleDrawer = () => {
				emit("toggle-drawer");
			}

			return {
				toggleDrawer,
				nameApp,
			}
		}
	});
