import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_ctx.loggedIn)
    ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
        key: 0,
        color: "blue-darken-4",
        dark: "",
        permanent: "",
        app: "",
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
        clipped: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, { density: "compact" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                "prepend-avatar": "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
                title: _ctx.getUser.email,
                subtitle: _ctx.getUser.role == 1 ? 'Usuario Cliente' : _ctx.getUser.role == 3 ? 'Usuario verificador' : 'Usuario Admin'
              }, null, 8, ["title", "subtitle"]),
              _createVNode(_component_v_list, { nav: "" }, {
                default: _withCtx(() => [
                  (_ctx.isAdmin || _ctx.isNormal || _ctx.isEmployee)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 0,
                        "prepend-icon": "mdi-home",
                        title: "Home",
                        to: {name: 'Home'}
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAdmin)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 1,
                        "prepend-icon": "mdi-table",
                        title: "Tablas de bingo",
                        to: { name: 'BingoTables' }
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAdmin)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 2,
                        "prepend-icon": "mdi-home",
                        title: "Cargar tablas",
                        to: {name: 'UploadExcel'}
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAdmin || _ctx.isEmployee)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 3,
                        "prepend-icon": "mdi-table",
                        title: "Juegos de bingo",
                        to: { name: 'GamesBingo' }
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isNormal)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 4,
                        "prepend-icon": "mdi-table",
                        title: "Juegos de bingo",
                        to: { name: 'GamesBingoNormal' }
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAdmin)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 5,
                        "prepend-icon": "mdi-table",
                        title: "Tipos cartones ganadores",
                        to: { name: 'TypeCardsWinners' }
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isAdmin)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 6,
                        "prepend-icon": "mdi-account",
                        title: "Usuarios",
                        to: { name: 'Users' }
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isEmployee)
                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                        key: 7,
                        "prepend-icon": "mdi-account-supervisor-circle",
                        title: "Tablas de bingo",
                        to: {name: 'BingoCardEmployee'}
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}